@font-face {
  font-family: 'SCoreDream';
  font-weight: 100;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream1.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream1.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 200;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream2.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream2.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 300;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream3.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream3.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 400;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream4.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream4.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 500;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream5.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream5.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 600;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream6.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream6.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 700;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream7.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream7.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 800;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream8.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream8.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SCoreDream';
  font-weight: 900;
  font-style: normal;
  src:
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream9.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/gh/webfontworld/SCoreDream/SCoreDream9.woff) format('woff');
  font-display: swap;
}
