@import './assets/fonts/pretendard/pretendard.css';
@import './assets/fonts/scoredream/scoredream.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gmarket Sans';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#root {
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
  outline: none;
}

a:hover,
a:active {
  text-decoration: none;
  color: #f59000;
}

.bg-footPrint {
  background-image: url(../src/assets/images/bg_footPrint.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.bg-footMission {
  background-image: url(../src/assets/images/img_mockup_footprint.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.bg-kkul {
  background-image: url(../src/assets/images/img_fifth.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.bg-main {
  background-image: url(../src/assets/images/img_main_bg.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.kkultrip-h1 {
  font-family: 'GmarketSans';
  font-weight: 700;
}
