@font-face {
  font-family: 'GmarketSans';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css');

@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  line-height: 1;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.font-g {
  font-family: 'GmarketSans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.font-noto {
  font-family: 'Noto Sans KR', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.font-nanum {
  font-family: 'Nanum Gothic', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.font-pre {
  font-family: 'Pretendard', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.font-s {
  font-family: 'S-CoreDream-3Light', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1,
h2 {
  font-weight: 500;
}

h3,
h4 {
  font-weight: 700;
}

h1.font-g,
h2.font-g,
h3.font-g,
h4.font-g {
  font-weight: 600;
}

button {
  /* border: 0; */
  outline: 0;
  background: transparent;
}
button:hover {
  cursor: pointer;
}

.main-bg {
  background-image: url(../images/pc_landingpage_bg.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.main-po-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.box-content {
  max-width: 600px;
  height: 130px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-main {
  width: 100%;
  height: 100%;
  max-width: 750px;
  max-height: 650px;
}

.img-second {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

.img-third {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 700px;
  object-fit: cover;
}

.img-fourth {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
}

.img-fifth {
  width: 100%;
  height: 80%;
  max-width: 1023px;
  max-height: 920px;
}

.img-sixth {
  width: 100%;
  height: 100%;
}

.img-seventh {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  max-height: 589px;
}

.app-button {
  border-radius: 8px;
  background: var(--brand-orange, #ffb127);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.icon-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px 32px;
  margin-top: 70px;
}

.navbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  margin: 24px 40px 0 0;
}

.nav-bar {
  display: absolute;
  top: 0;
  z-index: 10;
}

.nav-button {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.app-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  /* scroll-padding: 500px 0 0; */
  /* height: 100vh; */
}

.app-scroll-align {
  scroll-snap-align: center;
}

/* Modal Container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  z-index: 1002;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
}

.img-logo {
  width: 148.001px;
  height: 40.002px;
  flex-shrink: 0;
}

.img-qr {
  border: 2px solid #fcdb56;
  width: 220px;
  height: 220px;
}

.ic-delete {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.delete-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ic-name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--font-font-300, #3c3c3c);
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1px;
}

.img-fifth-mob {
  display: none;
}
.img-sixth-mob {
  display: none;
}
.img-third-mob {
  display: none;
}
.br-mob {
  display: block;
}

.img-seventh-mob {
  display: none;
}

.main-bg-mob {
  display: none;
}
.navbar-mob {
  display: none;
}

@media screen and (max-width: 1536px) {
  .navbar {
    top: 90%;
    right: 0;
    margin: 0;
  }

  .img-third {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
  }

  .img-fifth {
    display: none;
  }

  .img-fifth-mob {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 375px;
    max-height: 651px;
  }

  .img-third {
    display: none;
  }

  .img-third-mob {
    display: block;
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
    max-width: 600px;
    transform: translateX(-10%);
  }

  .br-mob {
    display: block;
  }

  .img-seventh {
    display: none;
  }

  .img-seventh-mob {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 375px;
    max-height: 651px;
  }
  .navbar {
    display: none;
  }
  .navbar-mob {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 100;
    display: flex;
    width: 100%;
  }
  .navbar-mob-box {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .main-po-bg {
    display: none;
  }

  .main-bg-mob {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }

  .img-main {
    display: block;
    /* width: 100%;
    height: 100%; */
    object-fit: fill;
    width: 130%;
    height: 80%;
  }

  .img-sixth {
    display: none;
  }

  .img-sixth-mob {
    display: block;
    width: 150%;
    object-fit: cover;
    transform: translateX(20%);
  }
}
